import React, { useState }  from 'react'
import { Link, NavLink  } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Header from './includes/Header';
import ProfilePic from './img/dp.jpg';

export default function AboutUs() {
  return (
    <div className='row content mb-5'>
        <Header />
        
        <div class="col-lg-10 container col-12 pt-5 other-side" style= {{ zIndex: "5"  }}>
            <div className="container">

                <div className='d-flex justify-content-between' style={{ borderBottom: "3px solid black", marginBottom: "5vh"}}>
                    <h1 className="fw-bolder unbounded">
                        About Us
                    </h1>
                    <Chip
                        avatar={<Avatar alt="Mr. Walter" src={ProfilePic} />}
                        label="Mr. Walter"
                        variant="filled"
                        color="default"
                        style={{ color: "black", cursor: "pointer", backgroundColor: "white", border: "2px solid black", fontFamily: "'Nunito', sans-serif", fontWeight: "bold"}}
                    /> 
                </div> 

                <div className="content">
                    <p>
                        At <strong>Gerf Pension</strong>, we are dedicated to providing comprehensive assistance and 
                        guidance to Trustees in navigating the complex landscape of pension schemes. 
                        Our expertise extends to re-designing schemes in alignment with prevailing market 
                        trends and evolving legislation, ensuring that our clients remain compliant and 
                        well-positioned for the future.Our services includes:
                    </p>

                    <br />

                    <h4 className='bordered-bottom unbounded'>Scheme Redesign and Compliance</h4>
                    <p>
                        We specialize in tailoring pension schemes to meet the dynamic needs of both Trustees and members. Through meticulous analysis and strategic planning, we ensure that schemes remain not only relevant but also compliant with the latest regulatory requirements and legislative changes. Our goal is to optimize the efficiency and effectiveness of pension provisions while safeguarding the interests of all stakeholders.
                    </p>

                    <h4 className='bordered-bottom unbounded'>Withdrawal Policy</h4>
                    <p>
                        As part of our commitment to prudent financial management, 
                        we have implemented a withdrawal policy that ensures the security and 
                        sustainability of pension funds. According to this policy, 
                        funds cannot be withdrawn from the account unless a minimum balance of $10,500 is 
                        maintained. This balance serves as a safeguard against premature or excessive 
                        withdrawals, preserving the long-term viability of the pension scheme.
                    </p>

                    <h4 className='bordered-bottom unbounded'>Performance Evaluation</h4>
                    <p>
                        Regular assessment of Pension Administrator performance is essential for maintaining the integrity and efficiency of pension schemes. Our team conducts comprehensive evaluations to gauge the performance of administrators, identify areas for improvement, and implement corrective measures where necessary. By upholding high standards of performance, we strive to enhance the overall management and administration of pension schemes.
                    </p>

                    <h4 className='bordered-bottom unbounded'>Benefits Management</h4>
                    <p>
                        We manage the intricate process of handling funds allocated towards individuals' pensions, ensuring seamless and accurate disbursements. Our streamlined approach encompasses everything from processing contributions to coordinating pension payments, alleviating the administrative burden on Trustees and providing peace of mind to scheme members.
                    </p>

                    <h4 className='bordered-bottom unbounded'>Pension Transfers and Arrangements</h4>
                    <p>
                        Facilitating the transfer of pension benefits from one scheme to another demands precision and expertise. Our team oversees the entire transfer process, from initial assessment to completion, ensuring a smooth transition for all parties involved. Additionally, we assist in arranging the payment of pensions, offering personalized solutions tailored to individual requirements and preferences.
                    </p>

                    <h4 className='bordered-bottom unbounded'>Collaboration with Regulatory Bodies</h4>
                    <p>
                        As a reputable player in the pension industry, we collaborate closely with regulatory bodies such as The Pensions Regulator (TPR). By adhering to regulatory guidelines and standards, we uphold the integrity and security of workplace pensions, fostering trust and confidence among employers and scheme members alike. Our commitment to transparency and compliance underscores our dedication to safeguarding the interests of all stakeholders.
                    </p>

                    <h4 className='bordered-bottom unbounded'>Our Mission</h4>
                    <p>
                        At <strong>Gerf Pension</strong>, our mission is to empower individuals to save safely and effectively for their retirement. By providing expert guidance, innovative solutions, and unwavering support, we strive to create a future where retirement planning is accessible, transparent, and rewarding for all. We are committed to being a strong and visible presence in the pension landscape, inspiring confidence and driving positive change.
                    </p>
                    <hr />

                    <p className='unbounded'>
                        As your trusted partner in pension management, we are here to navigate the complexities of retirement planning with you every step of the way. Join us in shaping a brighter future for retirement, one pension scheme at a time.
                    </p>
                </div>
               

            </div>
            
        </div>
    </div>
  )
}

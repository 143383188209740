import React from 'react'
import './css/Dashboard.css';
import { Link, NavLink  } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import LogoForm from './img/logo2.png';
import Header from './includes/Header';



export default function Withdraw() {
    

    
  return (
    <div className='row'>
        <Header />
        
        <div class="col-lg-10 container col-12 pt-5">
            <div className="container">
            <h1 className="nunito fw-bold ">
                Hi, Micheal
            </h1>

            <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Impedit unde, alias laboriosam error tenetur explicabo eum odit iste debitis itaque harum modi, aut ea dolorum voluptatem quo minima eveniet necessitatibus.
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et voluptatum ut cupiditate quas sed veniam pariatur magnam molestias velit praesentium temporibus quasi aliquam nam, obcaecati modi labore tempora neque error?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, reiciendis, deleniti voluptate cumque doloremque aut quasi eos est rerum necessitatibus a quos omnis? Illum fugiat adipisci dolore facere quibusdam laboriosam?
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id neque vitae dolores maxime error repudiandae tempore perspiciatis delectus unde nihil illo obcaecati ea ut perferendis, rerum laboriosam, nam possimus vel.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur autem numquam ipsa amet, beatae labore debitis cupiditate corporis harum suscipit enim. Pariatur quisquam nesciunt ex illum, fugit necessitatibus deleniti fugiat.

            </p>
            </div>
            
        </div>
    </div>
  )
}

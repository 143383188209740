import React, { useState }  from 'react'
import { Link, NavLink  } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Header from './includes/Header';
import ProfilePic from './img/dp.jpg';

export default function ContactUs() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        // Simulate loading for 5 seconds
        setTimeout(() => {
            setIsLoading(false);
            setSuccessMessage('We have received your message and will get back to you shortly.');
        }, 5000);
    };
  return (
    <div className='row content mb-5'>
        <Header />
        
        <div class="col-lg-10 container col-12 pt-5 other-side" style= {{ zIndex: "5"  }}>
            <div className="container">

                <div className='d-flex justify-content-between' style={{ borderBottom: "3px solid black", marginBottom: "5vh"}}>
                    <h1 className="fw-bolder unbounded">
                        Contact Us
                    </h1>
                    <Chip
                        avatar={<Avatar alt="Mr. Walter" src={ProfilePic} />}
                        label="Mr. Walter"
                        variant="filled"
                        color="default"
                        style={{ color: "black", cursor: "pointer", backgroundColor: "white", border: "2px solid black", fontFamily: "'Nunito', sans-serif", fontWeight: "bold"}}
                    />  
                </div> 

                <div className="container mt-5">
                       
                    {successMessage && (
                        <div className="alert alert-success mt-3 alert-dismissible fade show" role="alert">
                            {successMessage}
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input type="text" className="form-control" placeholder='Enter your name' id="name" name="name" value={formData.name} onChange={handleChange} required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" className="form-control" id="email" placeholder='Enter your email address' name="email" value={formData.email} onChange={handleChange} required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="message" className="form-label">Message</label>
                            <textarea className="form-control" id="message" name="message" placeholder='Enter your message' value={formData.message} onChange={handleChange} rows="5" required />
                        </div>
                        <button type="submit" className="form-btn">
                            Send Message &nbsp;
                            {isLoading && (
                                <div className="spinner-border text-dark spinner-border-sm" role="status">
                                </div>
                            )}
                        </button>
                        
                    </form>
                    
                    <br />
                    <h1 className="unbounded">
                        OR
                    </h1>
                    <br />

                    <a href="mailto:Gerfpension@gmail.com" className='fw-bold h5' style={{color: "black"}}>
                        Gerfpension@gmail.com
                    </a>
                </div>
               

            </div>
            
        </div>
    </div>
  )
}

import React, { useState, useRef } from 'react'
import { Link, NavLink  } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import LogoForm from './../img/logo2.png';
import './../css/Header.css';


function Header() {
  const handleLogout = () => {
    // Remove session data
    sessionStorage.removeItem('userData');

    // Redirect to the login page
    window.location.href = '/dashboard';
};

const [sidebarOpen, setSidebarOpen] = useState(false);
  const overlayRef = useRef(null);

  const openNav = () => {
    setSidebarOpen(true);
    document.body.style.backgroundColor = 'rgba(0, 0, 0, 0.4)';
    document.body.style.marginLeft = "270px";
    document.body.style.overflow = "hidden";
  };

  const closeNav = (e) => {
    // Check if the clicked element is the overlay or a link
    if (e.target === overlayRef.current || e.target.tagName === "A") {
      setSidebarOpen(false);
      document.body.style.backgroundColor = 'white';
      document.body.style.marginLeft = "0";
      document.body.style.overflowY = "scroll";
      document.body.style.overflowX = "hidden";
    }
  };
  
  return (
    <>
    
        <div class="side-bar col-lg-2 d-none d-lg-block">
        
            <img src={LogoForm} alt="Logo" className='logo' style={{ width: "150px", height: "auto", margin: "5vh auto 3vh"}} />
        

            <br />
            
            <div class="d-flex flex-column">

                <NavLink exact to="/dashboard" onlyActiveOnIndex className="sidebar-links" activeClassName="active">
                    Dashboard
                </NavLink>

                <NavLink exact to="/funds-management" onlyActiveOnIndex className="sidebar-links" activeClassName="active">
                    Funds Management
                </NavLink>

                <NavLink exact to="/about-us" onlyActiveOnIndex className="sidebar-links" activeClassName="active">
                    About Us
                </NavLink>

                <NavLink exact to="/contact-us" onlyActiveOnIndex className="sidebar-links" activeClassName="active">
                    Contact Us
                </NavLink>
            </div>

            <div className="fixed-bottom bottom-sidebar d-flex flex-column mx-4">
                <button onClick={handleLogout} className='white-button mb-2 d-flex align-items-center'>
                    <svg  stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.578 4.437a5 5 0 104.922.044l.5-.866a6 6 0 11-5.908-.053l.486.875z" clip-rule="evenodd"></path><path fill-rule="evenodd" d="M7.5 8V1h1v7h-1z" clip-rule="evenodd"></path></svg>
                    &nbsp; Logout
                </button>
                <p className="copyright" style={{fontSize: "12px"}}>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372zm5.6-532.7c53 0 89 33.8 93 83.4.3 4.2 3.8 7.4 8 7.4h56.7c2.6 0 4.7-2.1 4.7-4.7 0-86.7-68.4-147.4-162.7-147.4C407.4 290 344 364.2 344 486.8v52.3C344 660.8 407.4 734 517.3 734c94 0 162.7-58.8 162.7-141.4 0-2.6-2.1-4.7-4.7-4.7h-56.8c-4.2 0-7.6 3.2-8 7.3-4.2 46.1-40.1 77.8-93 77.8-65.3 0-102.1-47.9-102.1-133.6v-52.6c.1-87 37-135.5 102.2-135.5z"></path></svg>
                    Copyrights 2024 Gerf Pension
                </p>
            </div>
        </div>
        

        <div className='navbar-sm-container'>
          <div className='navbar-sm'>
              <img src={LogoForm} alt="Logo" className='logo' style={{ width: "150px", height: "auto",}} />
              <svg stroke="currentColor" onClick={sidebarOpen ? closeNav : openNav} style={{ fontSize: '30px', cursor: 'pointer' }} fill="none" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16"></path></svg>

          </div>
    
        {sidebarOpen && (
          <div style={{ zIndex: "10"  }}>
            <div id="mySidenav" className={`sidenav ${sidebarOpen ? 'open' : ''}`} >
              
                
                <NavLink exact onClick={closeNav}
              ref={overlayRef} to="/dashboard" onlyActiveOnIndex className="sidebar-links" activeClassName="active">
                    Dashboard
                </NavLink>

                <NavLink exact onClick={closeNav}
              ref={overlayRef} to="/funds-management" onlyActiveOnIndex className="sidebar-links" activeClassName="active">
                    Funds Management
                </NavLink>

                <NavLink exact onClick={closeNav}
              ref={overlayRef} to="/about-us"  onlyActiveOnIndex className="sidebar-links" activeClassName="active">
                    About Us
                </NavLink>

                <NavLink exact onClick={closeNav}
              ref={overlayRef} to="/contact-us" onlyActiveOnIndex className="sidebar-links" activeClassName="active">
                    Contact Us
                </NavLink>
            </div>
            <div
              className="backdrop"
              onClick={closeNav}
              ref={overlayRef}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)'}}
            ></div>
          </div>
        )}
      </div>
    </>
  )
}

export default Header
import React, { useState }  from 'react'
import { Link, NavLink  } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Header from './includes/Header';
import ProfilePic from './img/dp.jpg';


export default function FundsManagement() {
  return (
    <div className='row content'>
        <Header />
        
        <div class="col-lg-10 container col-12 pt-5 other-side" style= {{ zIndex: "5"  }}>
            <div className="container">

                <div className='d-flex justify-content-between' style={{ borderBottom: "3px solid black", marginBottom: "5vh"}}>
                    <h1 className="fw-bolder unbounded">
                        Funds Management
                    </h1>

                    <Chip
                        avatar={<Avatar alt="Mr. Walter" src={ProfilePic} />}
                        label="Mr. Walter"
                        variant="filled"
                        color="default"
                        style={{ color: "black", cursor: "pointer", backgroundColor: "white", border: "2px solid black", fontFamily: "'Nunito', sans-serif", fontWeight: "bold"}}
                    />  
                </div> 

                <div className="container mt-5">
                    <h3 className="nunito">
                        Pension payments are scheduled to be disbursed annually in August.
                    </h3>
                </div>
               

            </div>
            
        </div>
    </div>
  )
}

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import React, {Fragment} from 'react';
import LoginPage from './component/LoginPage'; 
import Dashboard from './component/Dashboard';
import Withdraw from './component/Withdraw'; 
import AboutUs from './component/About';
import FundsManagement from './component/FundsManagement';
import ContactUs from './component/ContactUs';

function App() {
  const userData = JSON.parse(sessionStorage.getItem('userData'));

  const PrivateRoute = ({ element, allowedRoles }) => {
    if (!userData || !userData.expirationTime || userData.expirationTime < Date.now()) {
      // Clear session data if expired and navigate to login
      sessionStorage.removeItem('userData');
      return <Navigate to="/login" />;
    }

    if (!allowedRoles.includes(userData.role)) {
      // Navigate to a restricted page or display an error message
      return <Navigate to="/login" />;
    }

    return element;
  };
  return (
    <Router>
       <Fragment>
       <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route exact path="/login" element={ <LoginPage /> } />
                <Route
                  exact
                  path="/dashboard"
                  element={<PrivateRoute element={<Dashboard />} allowedRoles={['user', 'admin']} />}
                />
                <Route
                exact
                  path="/withdraw"
                  element={<PrivateRoute element={<Withdraw />} allowedRoles={['user', 'admin']} />}
                />
                <Route
                  exact
                  path="/funds-management"
                  element={<PrivateRoute element={<FundsManagement />} allowedRoles={['user', 'admin']} />}
                />
                <Route
                  exact
                  path="/about-us"
                  element={<PrivateRoute element={<AboutUs />} allowedRoles={['user', 'admin']} />}
                />
                <Route
                  exact
                  path="/contact-us"
                  element={<PrivateRoute element={<ContactUs />} allowedRoles={['user', 'admin']} />}
                />
            </Routes>
       </Fragment>
    
  </Router>
  );
}

export default App;

import React, { useState }  from 'react'
import { Link, NavLink  } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ProfilePic from './../img/dp.jpg';

export default function Greetings() {
    const getGreeting = () => {
        const currentHour = new Date().getHours();
        let greeting = '';
    
        if (currentHour >= 5 && currentHour < 12) {
          greeting = 'Good morning';
        } else if (currentHour >= 12 && currentHour < 18) {
          greeting = 'Good afternoon';
        } else {
          greeting = 'Good evening';
        }
    
        return greeting;
      };

       
  return (
    <div className='d-flex justify-content-between' style={{ borderBottom: "3px solid black", marginBottom: "5vh"}}>
        <h1 className="fw-bolder unbounded">
            {getGreeting()}, Mr. Walter
        </h1>
        <Chip
            avatar={<Avatar alt="Mr. Walter" src={ProfilePic} />}
            label="Mr. Walter"
            variant="filled"
            color="default"
            style={{ color: "black", cursor: "pointer", backgroundColor: "white", border: "2px solid black", fontFamily: "'Nunito', sans-serif", fontWeight: "bold"}}
          />  

    </div>
  )
}

import React, { useState } from 'react';
import './css/LoginPage.css'; // Import CSS file from the css folder
import LogoForm from './img/logo.png';



const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const setSessionData = (userData) => {
        const now = new Date();
        const expirationTime = now.getTime() + 24 * 60 * 60 * 1000; // 24 hours
        const userDataWithExpiration = { ...userData, expirationTime };
        sessionStorage.setItem('userData', JSON.stringify(userDataWithExpiration));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Validate the input data
        if (!email || !password) {
            setError('Invalid email or password');
            return;
        }
         // Validate the input data
        const emailRegex = /^\S+@\S+\.\S+$/;
        const passwordRegex = /^(?=.*\d).{8,12}$/;
        if (!email.trim().match(emailRegex)) {
            setError('Invalid email or password');
            return;
        }
        if (!password.trim().match(passwordRegex)) {
            setError('Password must be between 8 to 12 characters.');
            return;
        }

        // Simulate loading for 10 seconds
        setLoading(true);
        setTimeout(() => {
            // Clear loading state
            setLoading(false);

            

            // Check the email and password
            if (email === 'walterzutell343@gmail.com' && password === 'walterrr343') {
                 // Save user data in session storage
                 const userData = {
                    email: email,
                    role: 'user' // Assign a default role, you can customize this based on your requirements
                };
                setSessionData(userData);
                window.location.href = '/dashboard';
            } else {
                setLoading(false);
                // Incorrect credentials, display error message
                setError('Incorrect email or password');
            }
        }, 7000); // 10 seconds timeout
    };

    return (
        <div className="login-page">
            <div className="form-container">
                <img src={LogoForm} alt="Logo" className='logo' />
                <p className="title montserat">Welcome back</p>
                <form className="form" onSubmit={handleSubmit}>
                    {error && 
                        <div class="alert show alert-danger fade alert-dismissible">
                            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                            <strong><div className="error-message">{error}</div></strong>
                        </div>
                    }
                    <input
                        type="email"
                        className="input"
                        placeholder="Email"
                        value={email}
                        autoFocus
                        autoComplete='on'
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        className="input"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {loading ? (
                        <div class="dot-spinner" style={{ margin: 'auto'}}>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                        </div>
                    ) : (
                        <>
                            <p className="page-link">
                                <span className="page-link-label">Forgot Password?</span>
                            </p>
                            <button type="submit" className="form-btn">Log in</button>
                        </>
                    )}
                    
                </form>
                {/**
                <p className="sign-up-label">
                    Don't have an account?<span className="sign-up-link">Sign up</span>
                </p> */}
            </div>
        </div>
    );
};

export default LoginPage;

import React, { useState } from 'react'
import './css/Dashboard.css';
import { Link, NavLink  } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import LogoForm from './img/logo2.png';
import Header from './includes/Header';
import Greetings from './includes/Greetings'
import Scotia from './img/scotia.png';



export default function Dashboard() {
    
  const [withdrawalAmount, setWithdrawalAmount] = useState(3000000);
  const [creditCardAmount, setCreditCardAmount] = useState(30000);
  const [refundSetAmount, setRefundSetAmount] = useState(27000);


  const totalAmount = withdrawalAmount + creditCardAmount + refundSetAmount;


  const [accNumber, setAccNumber] = useState('');
  const [transitNumber, setTransitNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState(null);

  const handleSubmit = () => {
    if (accNumber === '0473626' && transitNumber === '80762') {
        setIsLoading(true);
        setTimeout(() => {
            setBankDetails({
                name: 'Scotia Bank',
                logo: Scotia,
                ownerName: 'Walter Zutell' // You can replace this with the actual owner name
            });
            setIsLoading(false);
        }, 3000); // Simulating a 3-second delay
    } else {
        setBankDetails(null);
        alert('Invalid account details');
    }
};

const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'acc_number') {
        setAccNumber(value);
    } else if (name === 'transit_number') {
        setTransitNumber(value);
    }
};
  return (
    <div className='row content mb-5'>
        <Header />
        
        <div class="col-lg-10 container col-12 pt-5 other-side" style= {{ zIndex: "5"  }}>
            <div className="container">

                <Greetings /> 
               


                <div className="">
                    <h4 className="unbounded fw-bold">
                        Total Amount: ${totalAmount.toLocaleString()}
                    </h4>
                </div>
                <div className=' d-flex justify-content-center flex-column text-center mt-4'>
                    <div class="row g-4 d-flex justify-content-center">
                      <div className="col-md-6">
                          <div className="withdrawal card">
                              <h1 className="nunito fw-bold">Pension Funds Withdrawal</h1>
                              <br />
                              <h1 className="unbounded">${withdrawalAmount.toLocaleString()}</h1>
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="credit-card card">
                              <h1 className="nunito fw-bolder">Credit Card Funds</h1>
                              <br />
                              <h1 className="unbounded">${creditCardAmount.toLocaleString()}</h1>
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="refund-set card">
                              <h1 className="nunito fw-bold">Refund Sum Amount</h1>
                              <br />
                              <h1 className="unbounded">${refundSetAmount.toLocaleString()}</h1>
                          </div>
                      </div>

                      <div className="col-md-6">
                          <div className="refund-set card">
                              <h1 className="nunito fw-bold">Annual Payment</h1>
                              <br />
                              <h1 className="unbounded">--</h1>
                          </div>
                      </div>
                      <div>  
                    </div>
  
                    </div>

                    
                </div>

                <hr />

                <div className="statement">
                    <h4 className="unbounded fw-bold bordered-bottom">
                        Statement of account
                    </h4>

                    <i className="text-muted">
                       This account is a private acount used for personal use and withdrawal only.
                    </i>
                </div>
                <hr />
                <div className="fund-withdrawal">
                    <h4 className="unbounded fw-bold bordered-bottom">
                        Withdrawal
                    </h4>
                    <br />

                    <h5 className="nunito fw-bold">
                      Option for withdrawal: Bank transfer
                    </h5>
                    
                    
                    <div>
                      
                      {bankDetails && (
                          <div>
                              <p className='fw-bold'>Bank Account Name: {bankDetails.ownerName}</p>
                              <div className="d-flex align-items-center">
                                <img src={bankDetails.logo} alt={bankDetails.name} style={{width: "100px"}} />
                                <h5 className='nunito'>{bankDetails.name}</h5>
                              </div>
                              <hr />
                          </div>
                      )}
                      
                      <div className="mb-3">
                          <label htmlFor="acc_number" className="form-label fw-bold nunito">Bank Account Number:</label>
                          <input type="text" autoComplete='off' className="form-control" id="acc_number" name="acc_number" value={accNumber} onChange={handleInputChange} placeholder="Enter bank account number" />
                      </div>
                      <div className="mb-3">
                          <label htmlFor="transit_number" className="form-label fw-bold nunito">Transit/Routing Number:</label>
                          <input type="text" autoComplete='off'  className="form-control" id="transit_number" name="transit_number" value={transitNumber} onChange={handleInputChange} placeholder="Enter transit/routing number" />
                      </div>
                      <button className="form-btn" onClick={handleSubmit}>Confirm Bank Details &nbsp; {isLoading && <div class="spinner-border spinner-border-sm text-dark " role="status"></div>} </button>

                      
                    </div>
                   
                    <hr />
                    <i className='mb-2 d-flex align-content-center'>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M464 336a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path></svg>
                        &nbsp; 
                        <span>
                            On <strong>May 14, 2024</strong> , there's scheduled maintenance. Kindly settle the outstanding amount before then.
                        </span>
                    </i>
                    <br />
                    <div className="row">
                        <div className="col-md-9">
                            <h5 className='fw-bolder nunito bordered-bottom'>
                                Amount needed to be cleared
                            </h5>
                        </div>

                        <div className="d-flex flex-column col-md-3">
                          <h4 className="unbounded fw-bold" style={{marginRight: "10vw"}}>
                            $2,000
                          </h4>
                          
                          <p className="text-muted">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M464 336a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path></svg>
                                &nbsp;
                                Remittance tax is very important to prominent withdrawal and analysis of funds distributed to every private account.
                          </p>
                        </div>
                        
                    </div>
                </div>

                
            </div>
            
        </div>
    </div>
  )
}
